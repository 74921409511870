
.split_div{
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 10px;
    margin-top: 20px;
    flex-wrap: nowrap;
}

.split_div_notcent{
    display: flex;
    align-items: left;
    justify-content: space-between; 
    gap: 10px;
    margin-top: 20px;
}

.left_button{
    padding: 10px;
    width: 10vw;
    align-self: center;
}

.right_div{
    display: flex;
    align-self: center;
    margin-left: 5%;
}

.right_button{
    padding: 10px;
    width: 10vw;
    align-self: center;
}

.created_text{
    font-weight: 600;
    font-size: medium;
    font-family: 'Karla';
}

.deal_text{
    font-weight: 500;
    font-size: 15px;
    font-family: 'Karla';
}

.el_padding{
    margin-top: 10px;
}

.estimate_text{
    font-weight: 600;
    padding: 10px;
    font-size: medium;
    font-family: 'Karla';
    /*margin-left: 10%;*/
    align-self: center;
}

.not_found_text{
    font-weight: 600;
    font-size: medium;
    font-family: 'Karla';
    margin-left: 30%;
    margin-bottom: 40%;
}

.horizontal_div{
    padding: 10px;
    display: flex;
}

.hor_left{
    display: flex;
}

.hor_right{
    display: flex;
    font-weight: 550;
    font-size: 15px;
    font-family: 'Karla';
    margin-left: 10px;
}