.button-style {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #008cff;
    color: #fff;
    border: none;
    border-radius: 15px; /* More rounded corners */
    cursor: pointer;
    font-size: 16px;
    font-style:normal;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    box-shadow: 15px 0px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
}


.button-style:hover {
    background-color: #006bb3; /* Slightly darker blue on hover */
    box-shadow: 15px 0px 6px rgba(0, 0, 0, 0.15); /* Increase shadow on hover */
    color: #ffffff;   
}

.div-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #f0f0f0;
}

.quote-style-border {
    width: 100vw;
    padding: 10px;
    height: 10px;
    align-items: center;
}



.quote-style-h2 {
    width: 100vw;
    padding: 0px;
    height: 10px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px; /* Assuming you meant px here */
    color: white;
    font-size: 18px;
    font-family: "basic"; /* Make sure the font-family 'basic' is defined or available */
    font-weight: bold;
}

.full-screen {
    width: 100%;
    height: 100%;
    border: none;
}

.iframe-style {
    width: 100vw;
    height: calc(100vh - 120px);
}