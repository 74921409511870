.image-upload-container {
    margin-bottom: 20px;
  }
  
  .image-preview {
    margin-top: 10px;
    position: relative;
  }
  
  .image-preview img {
    max-width: 100%;
    max-height: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .image-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    color: #ff0000;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }