.values-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

}

.values-list div {
    display: flex;
    justify-content: space-between;
}

.value-name {
    text-align: left;
    margin-right: 5px;
    font-family: 'karla';
}

.value {
    font-family: 'karla';
    text-align: right;
    font-weight: bold;
    margin-left: 5px;
}