.container {
    background-color: #f0f0f0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .subcontainer {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column; /* Change to column layout */
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .logo {
    max-width: '10vw';
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .signupButton {
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    background-color: #0078D4;
    color: #fff;
    font-family: Karla, sans-serif;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  .inputField {
    width: 80%;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    background-color: white;
    /* color: #fff; */
    font-family: Karla, sans-serif;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  .errorMessage {
    font-size: 12px;
    margin-top: 10px;
    color: red;
    font-family: Karla, sans-serif;
  }
  