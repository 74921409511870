.white-icon {
  color: white;
  padding: 4px; /* Adjust padding to reduce clickable area */
}

.input-field-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%; 
  margin-bottom: 10px;
}

.regular-dropdown {
  width: 100%;
  height: 40px;
  border: 1px solid var(--inside-border-color);
  background-color: var(--bg-color-secondary);
  color: var(--text-color);
  border-radius: 5px;
  padding: 5px;
}

.regular-dropdown-option {
  width: 100%;
  height: 20px;
}

.regular-input-field {
  width: 100%;
  height: 20px;
  border: 1px solid var(--inside-border-color);
  background-color: var(--bg-color-secondary);
  color: var(--text-color);
  border-radius: 5px;
  padding: 5px;
}