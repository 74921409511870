.excel-upload-container {
    margin-top: 20px;
  }
  
  .span-excel-selected {
    font-size: 0.9rem;
    font-family: karla;
    font-weight:600;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 5px; 
  }
  
  .excel-preview img {
    max-width: 100%;
    max-height: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .excel-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    color: #ff0000;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }